import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import "./input.css";
import stock from "./images/stock.jpg"
import { useAuth0 } from "@auth0/auth0-react";

function Nav() {
    const { logout } = useAuth0();
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    if (!isAuthenticated) {
        return (
            <>
                <nav className="bg-white border-gray-200 dark:bg-gray-900">
                    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                        <NavLink
                            to="/"
                            className="flex items-center"
                        >
                        <img src={stock} className="h-8 mr-3" alt="From https://www.pexels.com/photo/low-angle-view-of-lighting-equipment-on-shelf-257904/" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">UpTrendDownTrend</span>
                        </NavLink>
                    <div className="flex md:order-2">
                        <button
                            type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => loginWithRedirect()}
                        >
                            Login
                        </button>
                        <button
                            data-collapse-toggle="navbar-cta" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-cta" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                            </svg>
                        </button>
                    </div>
                    </div>
                </nav>
            </>
        )
    } else {
        return (
            <>
                <nav className="bg-white border-gray-200 dark:bg-gray-900">
                    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                        <NavLink to="/dashboard" className="flex items-center">
                            <img src={stock} className="h-8 mr-3" alt="From https://www.pexels.com/photo/low-angle-view-of-lighting-equipment-on-shelf-257904/" />
                            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">UpTrendDownTrend</span>
                        </NavLink>
                        <div>
                            <div className="burger-drop md:hidden">
                                <button
                                    onClick={toggleDropdown}
                                    className="text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                >
                                    <svg className={`w-5 h-5 ${isDropdownOpen ? 'hidden' : 'block'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                    <svg className={`w-5 h-5 ${isDropdownOpen ? 'block' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                </button>
                            </div>
                            <div className={`md:flex md:w-auto md:order-1 ${isDropdownOpen ? 'block' : 'hidden'}`}>
                                <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                    <li>
                                        <NavLink to="dashboard" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Dashboard</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/symbols" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">All Symbols</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/collections" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Collections</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/details" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Symbol Search</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex md:order-2">
                            {isAuthenticated ? (
                                <>
                                    <button
                                        type="button"
                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                                    >
                                        Logout
                                    </button>
                                </>
                            ) : (
                                <button
                                    type="button"
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={() => loginWithRedirect()}
                                >
                                    Login
                                </button>
                            )}
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default Nav;
