import { useState } from "react";


function CreateSymbol() {

    const [symbol, setSymbol] = useState("");

    const handleSubmit = async (event) => {
      event.preventDefault();
      const data = {};
      data.symbol = symbol;
      data.is_active = true;
      data.is_favorite = true;

      const symbolUrl = `${process.env.REACT_APP_API_HOST}/api/symbols/`;
      const fetchConfig = {


        method: "post",
        body: JSON.stringify(data),
        headers: {
        'Content-Type': 'application/json',
          },
      };
      const response = await fetch(symbolUrl, fetchConfig);
      if (response.ok){
        setSymbol("")
        alert("Symbol has been added")
      }
        else if (response.isError) {
        alert(response.error);
      }
    }

    return (
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-one-third">
              <div className="w-full max-w-xs">
                <form
                  onSubmit={handleSubmit}
                  className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                >
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="stock_symbol"
                    >
                      Stock Symbol
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="symbol"
                      type="text"
                      placeholder="Stock Symbol"
                      onInput={(e) => setSymbol(e.target.value)}
                      value = {symbol}
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      );
}

export default CreateSymbol
