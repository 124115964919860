import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

function SymbolSearch() {
    const [symbol, setSymbol] = useState("");
    const [symbolsData, setSymbolsData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate(); // Use navigate from react-router-dom

    // Fetch symbols data from API
    useEffect(() => {
        const fetchSymbols = async () => {
            const symbolsUrl = `${process.env.REACT_APP_API_HOST}/api/symbols`;

            try {
                const symbolsResponse = await fetch(symbolsUrl);
                if (symbolsResponse.ok) {
                    const symbolsData = await symbolsResponse.json();
                    setSymbolsData(symbolsData);
                } else {
                    console.error("Failed to fetch symbols.");
                }
            } catch (error) {
                console.error("Error fetching symbols:", error);
            }
        };

        fetchSymbols();
    }, []);

    // Handle input changes and update suggestions
    const handleInputChange = (event) => {
        const input = event.target.value.toUpperCase(); // Convert input to uppercase
        setSymbol(input);

        // Filter and set suggestions
        if (input.length > 0) {
            const filteredSuggestions = symbolsData
                .filter(data => data.symbol.startsWith(input))
                .map(data => data.symbol)
                .slice(0, 5); // Limit suggestions
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    // Handle search functionality
    const handleSearch = () => {
        const capitalSymbol = symbol.toUpperCase();
        if (symbolsData.some((data) => data.symbol === capitalSymbol)) {
            navigate(`/details/${capitalSymbol}`);
        } else {
            alert("Symbol not found. Please add to universe.");
        }
    };

    // Handle suggestion click
    const handleSuggestionClick = (suggestion) => {
        setSymbol(suggestion);
        setSuggestions([]);
        navigate(`/details/${suggestion}`);
    };

    // Handle navigation to add symbol page
    const handleAddSymbolClick = () => {
        navigate("/dashboard/controlpanel"); // Use navigate for routing to control panel
    };

    return (
        <div className="symbol-search-main" style={{ textAlign: 'center' }}>
            <h1 style={{ fontSize: '3rem' }}>Symbol Search</h1>
            <br />
            <h3 style={{ padding: '1rem' }}>Search For a Symbol</h3>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <input
                    className="SymbolSearch"
                    type="text"
                    placeholder="Search"
                    value={symbol}
                    onChange={handleInputChange}
                    onKeyDown={(event) => event.key === 'Enter' && handleSearch()} // Trigger search on Enter
                    style={{
                        padding: '.5rem',
                        borderRadius: '5px',
                        textAlign: 'center',
                        color: '#000000',
                        width: '200px',
                        backgroundColor: 'white', // Ensure background is white for contrast
                        border: '1px solid #ccc',
                    }}
                />
                {suggestions.length > 0 && (
                    <ul
                        style={{
                            listStyleType: 'none',
                            padding: 0,
                            margin: 0,
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            width: '100%',
                            backgroundColor: 'white',
                            zIndex: 1000,
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            maxHeight: '150px', // Limit height for scroll
                            overflowY: 'auto',
                        }}
                    >
                        {suggestions.map((suggestion) => (
                            <li
                                key={suggestion}
                                onClick={() => handleSuggestionClick(suggestion)}
                                style={{
                                    padding: '0.5rem',
                                    cursor: 'pointer',
                                    color: '#000', // Ensure text is black for visibility
                                }}
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div style={{ marginTop: '10px' }}>
                <button
                    style={{
                        marginTop: '5px',
                        padding: '.5rem 1rem',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        backgroundColor: '#134f42',
                        color: 'white',
                        cursor: 'pointer',
                    }}
                    onClick={handleSearch}
                >
                    Search
                </button>
            </div>
            <div style={{ marginTop: '40px', color: 'white', fontWeight: 'bold' }}>
                Can't find Symbol?
            </div>
            <div style={{ marginTop: '10px' }}>
                <button
                    style={{
                        padding: '.5rem 1rem',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        backgroundColor: '#134f42',
                        color: 'white',
                        cursor: 'pointer',
                    }}
                    onClick={handleAddSymbolClick} // Use handleAddSymbolClick function
                >
                    Add Symbol to Universe
                </button>
            </div>
        </div>
    );
}

export default SymbolSearch;
