import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import './component.css';

function Controlpanel() {
    const [activeSection, setActiveSection] = useState('');
    const { user, getAccessTokenSilently } = useAuth0(); // Add getAccessTokenSilently
    const [loading, setLoading] = useState(false); // Add loading state
    const [loadingMessage, setLoadingMessage] = useState(''); // Add loading message state

    useEffect(() => {
        const storedSection = localStorage.getItem('activeSection');
        if (storedSection) {
            setActiveSection(storedSection);
        }
    }, []);

    const handleSectionChange = (section) => {
        setActiveSection(section);
        localStorage.setItem('activeSection', section);
    };

    // Symbol Add, Update, Remove

    const [symbol, setSymbol] = useState("");
    const [bulkSymbols, setBulkSymbols] = useState([]);
    const [removeSymbol, setRemoveSymbol] = useState("");
    const [symbolsData, setSymbolsData] = useState([]);

    const fetchSymbols = useCallback(async () => {
        const symbolsUrl = `${process.env.REACT_APP_API_HOST}/api/symbols`;
        try {
            const token = await getAccessTokenSilently(); // Get the access token
            const symbolsResponse = await fetch(symbolsUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (symbolsResponse.ok) {
                const symbolsData = await symbolsResponse.json();
                setSymbolsData(symbolsData);
            } else {
                console.error('Failed to fetch symbols:', symbolsResponse.statusText);
            }
        } catch (error) {
            console.error('Error fetching symbols:', error);
        }
    }, [getAccessTokenSilently]);

    useEffect(() => {
        fetchSymbols();
    }, [fetchSymbols]);

    const symbolsArray = symbolsData.map(symbol => symbol.symbol);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setLoadingMessage('Adding symbol...');
        const data = {
            symbol: symbol.toUpperCase(),
            is_active: true,
            is_favorite: true
        };

        const symbolUrl = `${process.env.REACT_APP_API_HOST}/api/symbols`;
        try {
            const token = await getAccessTokenSilently(); // Get the access token
            const fetchConfig = {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await fetch(symbolUrl, fetchConfig);
            setLoading(false);

            if (response.ok) {
                const responseData = await response.json();
                const newSymbolId = responseData.symbol_id;
                if (symbolsArray.includes(data.symbol)) {
                    alert("Symbol already exists");
                } else {
                    setSymbol("");
                    alert("Symbol has been added");
                    fetchSymbols();
                    await runFullUpdateForSymbol(newSymbolId);
                }
            } else {
                alert("Failed to add symbol");
            }
        } catch (error) {
            setLoading(false);
            console.error('Error adding symbol:', error);
            alert("Failed to add symbol: " + error.message);
        }
    };

    const runFullUpdateForSymbol = async (symbolId) => {
        const updateUrl = `${process.env.REACT_APP_API_HOST}/api/run_full_update/${symbolId}`;
        try {
            const token = await getAccessTokenSilently(); // Get the access token
            const fetchConfig = {
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await fetch(updateUrl, fetchConfig);
            if (!response.ok) {
                alert("Failed to initiate full update for the new symbol");
            }
        } catch (error) {
            console.error('Error initiating full update:', error);
            alert("Failed to initiate full update: " + error.message);
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const text = event.target.result;
                const lines = text.split(/\r\n|\n/);
                const symbols = lines.map(line => line.split(',')[0].trim().toUpperCase()).filter(Boolean);
                setBulkSymbols(symbols);
                console.log('Uploaded symbols:', symbols); // Log the uploaded symbols
            };
            reader.onerror = function () {
                alert('Failed to read the file');
            };
            reader.readAsText(file);
        }
    };

    const handleBulkSubmit = async () => {
        if (!bulkSymbols.length) {
            alert('No symbols to add');
            return;
        }

        setLoading(true);
        setLoadingMessage('Uploading symbols... This may take a few seconds.');

        const data = {
            symbols: bulkSymbols
        };

        const symbolBulkUrl = `${process.env.REACT_APP_API_HOST}/api/symbols/bulk`;
        try {
            const token = await getAccessTokenSilently(); // Get the access token
            const fetchConfig = {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await fetch(symbolBulkUrl, fetchConfig);
            const responseData = await response.json();
            setLoading(false);
            if (response.ok) {
                const { success_count, error_count, added, errors } = responseData;
                const addedSymbols = added.map(item => item.symbol).join(', ');
                const errorMessages = errors.map(error => error.message).join('\n');

                alert(`Success: ${success_count} symbols added. Errors: ${error_count} symbols failed.\n\nAdded Symbols:\n${addedSymbols}\n\nErrors:\n${errorMessages}`);
                fetchSymbols();

                // Trigger full update for all symbols
                await runFullUpdateAll();
            } else {
                console.error('Error adding bulk symbols:', responseData);
                alert("Failed to add bulk symbols: " + responseData.message);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error in handleBulkSubmit:', error);
            alert("An error occurred while sending the data: " + error.message);
        }
    };

    const runFullUpdateAll = async () => {
        const updateUrl = `${process.env.REACT_APP_API_HOST}/api/run_full_update_all`;
        try {
            const token = await getAccessTokenSilently(); // Get the access token
            const fetchConfig = {
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await fetch(updateUrl, fetchConfig);
            if (!response.ok) {
                alert("Failed to initiate full update for all symbols");
            }
        } catch (error) {
            console.error('Error initiating full update for all symbols:', error);
            alert("Failed to initiate full update for all symbols: " + error.message);
        }
    };

    const handleRemove = async (event) => {
        event.preventDefault();
        setLoading(true);
        setLoadingMessage('Removing symbol...');
        const upperRemoveSymbol = removeSymbol.toUpperCase();
        const url = `${process.env.REACT_APP_API_HOST}/api/symbols/${upperRemoveSymbol}`;
        try {
            const token = await getAccessTokenSilently(); // Get the access token
            const fetchConfig = {
                method: "delete",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await fetch(url, fetchConfig);
            setLoading(false);

            if (response.ok) {
                if (symbolsArray.includes(upperRemoveSymbol)) {
                    setRemoveSymbol("");
                    alert("Symbol has been removed");
                    fetchSymbols();
                } else {
                    alert("Symbol removal failed");
                }
            } else {
                alert("Symbol removal failed");
            }
        } catch (error) {
            setLoading(false);
            console.error('Error removing symbol:', error);
            alert("Failed to remove symbol: " + error.message);
        }
    };

    return (
        <>
            <div className="controlpanel-main">
                {loading && <div className="loading-overlay">{loadingMessage}</div>}
                <div>
                    <h1 className="controlpanel-title">Control Panel</h1>
                </div>
                <div className="controlpanel-nav">
                    <button
                        className="btn-one"
                        style={{
                            borderColor: activeSection === 'section1' ? 'white' : 'grey',
                            backgroundColor: activeSection === 'section1' ? '#8E8EDC3D' : 'transparent'
                        }}
                        onClick={() => handleSectionChange('section1')}
                    >
                        SYMBOLS
                    </button>
                </div>

                {activeSection === 'section1' && (
                    <div>
                        <div className="sec-one-contain container">
                            <div className="columns is-centered">
                                <div className="column is-one-third">
                                    <div className="w-full max-w-xs">
                                        <form
                                            onSubmit={handleSubmit}
                                            className="shadow-md rounded px-8 pt-6 pb-8 mb-4"
                                            style={{ border: '1px solid white' }}
                                        >
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-700 text-sm font-bold mb-2"
                                                    style={{ color: 'white', fontSize: '18px' }}
                                                >
                                                    Add Symbol
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="symbol"
                                                    type="text"
                                                    placeholder="Stock Symbol"
                                                    onInput={(e) => setSymbol(e.target.value)}
                                                    value={symbol}
                                                />
                                            </div>

                                            <div className="flex items-center justify-between" style={{ justifyContent: 'center' }}>
                                                <button
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sec-one-contain container">
                            <div className="columns is-centered">
                                <div className="column is-one-third">
                                    <div className="w-full max-w-xs">
                                        <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4"
                                            style={{ border: '1px solid white' }}
                                        >
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-700 text-sm font-bold mb-2"
                                                    style={{ color: 'white', fontSize: '18px' }}
                                                >
                                                    Update Symbol List
                                                </label>
                                                <input
                                                    type="file"
                                                    accept=".csv"
                                                    onChange={handleFileUpload}
                                                />
                                            </div>
                                            <div className="flex items-center justify-between" style={{ justifyContent: 'center' }}>
                                                <button
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                    style={{ marginBottom: '8px', fontSize: '12px', color: '#FFF' }}
                                                    type="submit"
                                                    onClick={handleBulkSubmit}
                                                >
                                                    Upload CSV and Add Symbols
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sec-one-contain container">
                            <div className="columns is-centered">
                                <div className="column is-one-third">
                                    <div className="w-full max-w-xs">
                                        <form
                                            onSubmit={handleRemove}
                                            className="shadow-md rounded px-8 pt-6 pb-8 mb-4"
                                            style={{ border: '1px solid white' }}
                                        >
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-700 text-sm font-bold mb-2"
                                                    style={{ color: 'white', fontSize: '18px' }}
                                                >
                                                    Remove Symbol
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="remove_symbol"
                                                    type="text"
                                                    placeholder="Remove Stock Symbol"
                                                    onInput={(e) => setRemoveSymbol(e.target.value)}
                                                    value={removeSymbol}
                                                />
                                            </div>

                                            <div className="flex items-center justify-between" style={{ justifyContent: 'center' }}>
                                                <button
                                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                    type="submit"
                                                    onClick={handleRemove}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Controlpanel;
