import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./input.css";
import Auth0ProviderWithNavigate from "./auth0-provider-with-navigate";

const container = document.getElementById("root");
const root = createRoot(container);

// Extract environment variables
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0CallbackUrl = process.env.REACT_APP_AUTH0_CALLBACK_URL;
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE;

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate
        domain={auth0Domain}
        clientId={auth0ClientId}
        redirectUri={auth0CallbackUrl}
        audience={auth0Audience}
        scope={auth0Scope}
      >
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);
