import './component.css';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function Dashboard () {
    const { user, getAccessTokenSilently } = useAuth0();

    const token = getAccessTokenSilently();
    console.log(token)

    return (
        <>
            <div className="dashboard-main">
                <div className="dashboard-welcome">
                    Welcome back, {user.name}
                </div>
                    <div className="cntrlpnl-grid-container">
                        <div className="cntrlpnl-grid-item-c">
                            <Link to="/dashboard/controlpanel"
                                className="link-to-css"
                            >
                                    <div className="cntrlpnl-title">
                                        Control Panel
                                    </div>
                                    <div className="cntrlpnl-img">
                                        <img src="https://img.icons8.com/?size=512&id=uY7ZEuGXILwn&format=png" alt=""/>
                                    </div>
                            </Link>
                        </div>
                        <div className="cntrlpnl-grid-item-d">
                            <Link to="/collections"
                                className="link-to-css"
                            >
                                    <div className="collections-title">
                                        Collections
                                    </div>
                                    <div className="collections-img">
                                        <img src="https://img.icons8.com/?size=512&id=46088&format=png" alt=""/>
                                    </div>
                            </Link>
                        </div>
                        <div className="cntrlpnl-grid-item-a">
                            <Link to="/symbols"
                                className="link-to-css"
                            >
                                    <div className="symbols-title">
                                        Symbols
                                    </div>
                                    <div className="symbols-img">
                                        <img src="https://img.icons8.com/?size=512&id=43594&format=png" alt=""/>
                                    </div>
                            </Link>
                        </div>
                        <div className="cntrlpnl-grid-item-b">
                            <Link to="/details"
                                className="link-to-css"
                            >
                                    <div className="symbol-details-title">
                                        Symbol Details
                                    </div>
                                    <div className="symbol-details-img">
                                        <img src="https://img.icons8.com/?size=512&id=79455&format=png" alt=""/>
                                    </div>
                            </Link>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default Dashboard;
