import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './component.css';

function Main() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    window.location.href = "/dashboard";
  } else {
      return(
          <>
            <div className="main-container">
              <div className="main-welcome-one">
                UpTrend
              </div>
              <div className="main-welcome-two">
                DownTrend
              </div>
              <div>
                <button className="main-login-button" onClick={() => loginWithRedirect()}>Log In | Sign Up</button>
              </div>
            </div>
          </>
      )
    }
  }

export default Main;
