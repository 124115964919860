import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './component.css';

function Collections() {
    const { user, getAccessTokenSilently } = useAuth0();
    const [selectedCollection, setSelectedCollection] = useState('');
    const [collection, setCollection] = useState('');
    const [removeCollection, setRemoveCollection] = useState('');
    const [symbol, setSymbol] = useState("");
    const [bulkSymbols, setBulkSymbols] = useState([]);
    const [removeSymbol, setRemoveSymbol] = useState("");
    const [symbolsData, setSymbolsData] = useState([]);
    const [quickFilterText, setQuickFilterText] = useState('');
    const [manageQuickFilterText, setManageQuickFilterText] = useState('');
    const [collectionsData, setCollectionsData] = useState([]);
    const [listCollections, setListCollections] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [removeRowData, setRemoveRowData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [oneSelection, setOneSelection] = useState("");
    const gridRef = useRef();

    const userIgn = user.nickname;

    const fetchSymbols = useCallback(async () => {
        const token = await getAccessTokenSilently({
            audience: "https://uptrenddowntrend.com/api",
            scope: "openid profile email",
        });
        const symbolsUrl = `${process.env.REACT_APP_API_HOST}/api/symbols`;
        const symbolsResponse = await fetch(symbolsUrl, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (symbolsResponse.ok) {
            const symbolsData = await symbolsResponse.json();
            setSymbolsData(symbolsData);
        }
    }, [getAccessTokenSilently]);

    useEffect(() => {
        fetchSymbols();
    }, [fetchSymbols]);

    const fetchCollections = useCallback(async () => {
        try {
            const token = await getAccessTokenSilently({
                audience: "https://uptrenddowntrend.com/api",
                scope: "openid profile email",
            });

            const infoUrl = `${process.env.REACT_APP_API_HOST}/api/collections`;
            const response = await fetch(infoUrl, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                setListCollections(data);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently]);

    useEffect(() => {
        fetchCollections();
    }, [fetchCollections]);

    const symbolsArray = symbolsData.map(data => data.symbol);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            symbol: symbol.toUpperCase(),
            is_active: true,
            is_favorite: true
        };

        const token = await getAccessTokenSilently({
            audience: "https://uptrenddowntrend.com/api",
            scope: "openid profile email",
        });

        const symbolUrl = `${process.env.REACT_APP_API_HOST}/api/symbols`;
        const fetchConfig = {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };

        const response = await fetch(symbolUrl, fetchConfig);

        if (response.ok) {
            setSymbol("");
            alert("Symbol has been added");
            fetchSymbols();
        } else {
            const errorData = await response.json();
            alert(errorData.message);
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function(event) {
                const text = event.target.result;
                const lines = text.split(/\r\n|\n/);
                const symbols = lines.map(line => line.split(',')[0].trim()).filter(Boolean);
                setBulkSymbols(symbols);
            };
            reader.onerror = function() {
                alert('Failed to read the file');
            };
            reader.readAsText(file);
        }
    };

    const handleBulkSubmit = async () => {
        if (!bulkSymbols.length) {
            alert('No symbols to add');
            return;
        }

        const token = await getAccessTokenSilently({
            audience: "https://uptrenddowntrend.com/api",
            scope: "openid profile email",
        });

        const data = { symbols: bulkSymbols };

        const symbolBulkUrl = `${process.env.REACT_APP_API_HOST}/api/symbols/bulk`;
        const fetchConfig = {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        try {
            const response = await fetch(symbolBulkUrl, fetchConfig);
            const responseData = await response.json();
            if (response.ok) {
                const { success_count, error_count, added, errors } = responseData;
                const addedSymbols = added.map(item => item.symbol).join(', ');
                const errorMessages = errors.map(error => error.message).join('\n');

                alert(`Success: ${success_count} symbols added. Errors: ${error_count} symbols failed.\n\nAdded Symbols:\n${addedSymbols}\n\nErrors:\n${errorMessages}`);
                fetchSymbols();
            } else {
                alert("Failed to add bulk symbols: " + responseData.message);
            }
        } catch (error) {
            alert("An error occurred while sending the data: " + error.message);
        }
    };

    const handleRemove = async (event) => {
        event.preventDefault();

        const symbolToRemove = removeSymbol.toUpperCase();
        const token = await getAccessTokenSilently({
            audience: "https://uptrenddowntrend.com/api",
            scope: "openid profile email",
        });

        const url = `${process.env.REACT_APP_API_HOST}/api/symbols/${symbolToRemove}`;
        const fetchConfig = {
            method: "delete",
            headers: {
                'Authorization': `Bearer ${token}`
            },
        };

        const response = await fetch(url, fetchConfig);

        if (response.status === 200) {
            setRemoveSymbol("");
            alert("Symbol has been removed");
            fetchSymbols();
        } else {
            alert("Symbol removal failed");
        }
    };

    const handleCollectionSubmit = async (event) => {
        event.preventDefault();

        try {
            const token = await getAccessTokenSilently({
                audience: "https://uptrenddowntrend.com/api",
                scope: "openid profile email",
            });

            const data = {
                collection_name: collection,
                created_by: userIgn
            };

            const collectionUrl = `${process.env.REACT_APP_API_HOST}/api/collections`;
            const fetchConfig = {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };

            const response = await fetch(collectionUrl, fetchConfig);

            if (response.ok) {
                const responseData = await response.json();
                const newCollection = responseData.collection_id;
                alert("Collection has been added");
                setCollection("");
                setSelectedCollection(newCollection); // Set the new collection as the selected collection
                localStorage.setItem('selectedCollection', newCollection);
                fetchCollections();
            } else {
                const errorData = await response.json(); // Assuming the error response contains a JSON with an 'error' field
                alert(errorData.error);
            }
        } catch (error) {
            console.error("Error adding collection:", error);
        }
    };

    const handleRemoveCollection = async (event) => {
        event.preventDefault();

        const token = await getAccessTokenSilently({
            audience: "https://uptrenddowntrend.com/api",
            scope: "openid profile email",
        });

        const url = `${process.env.REACT_APP_API_HOST}/api/collections/${removeCollection}`;
        const fetchConfig = {
            method: "delete",
            headers: {
                'Authorization': `Bearer ${token}`
            },
        };

        const response = await fetch(url, fetchConfig);

        if (response.status === 200) {
            setRemoveCollection("");
            alert("Collection has been removed");
            setSelectedCollection('');
            fetchCollections();
        } else {
            alert("Collection removal failed");
        }
    };

    const handleCollectionChange = (collection) => {
        setSelectedCollection(collection);
        localStorage.setItem('selectedCollection', collection);
    };

    const isFirstColumn = (params) => {
        const displayedColumns = params.columnApi.getAllDisplayedColumns();
        return displayedColumns[0] === params.column;
    };

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '30vh', minWidth: '30vw', maxWidth: '60vw' }), []);
    const [columnDefs, setColumnDefs] = useState([
        { headerName: 'Symbol ', field: 'symbol', minWidth: 30, maxWidth: 150, headerClass: 'header-center' },
        { headerName: 'Company Name', field: 'company_name', minWidth: 300, headerClass: 'header-center' },
    ]);
    const [removeColumnDefs, setRemoveColumnDefs] = useState([
        { headerName: 'Symbol ', field: 'symbol', maxWidth: 150, headerClass: 'header-center' },
        { headerName: 'Company Name', field: 'company_name', minWidth: 300, headerClass: 'header-center' },
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            headerCheckboxSelection: isFirstColumn,
            checkboxSelection: isFirstColumn,
        };
    }, []);

    const onGridReady = useCallback(() => {
        setRowData(symbolsData);
    }, [symbolsData]);

    const onQuickFilterChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(document.getElementById('quickFilter').value);
    }, []);

    const onRemoveGridReady = useCallback(async () => {
        try {
            const token = await getAccessTokenSilently({
                audience: "https://uptrenddowntrend.com/api",
                scope: "openid profile email",
            });

            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/collections/link/${selectedCollection}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const secondData = await response.json();

            const removeSymbolFromArray = [];
            const existingSymbols = new Set();

            for (let i = 0; i < secondData.length; i++) {
                const symbolId = secondData[i].symbol_id;

                if (!existingSymbols.has(symbolId)) {
                    existingSymbols.add(symbolId);

                    const symbolData = symbolsData.find(symbol => symbol.symbol_id === symbolId);

                    if (symbolData) {
                        removeSymbolFromArray.push({ symbol: symbolData.symbol, company_name: symbolData.company_name, symbol_id: symbolData.symbol_id });
                    }
                }
            }

            setRemoveRowData(removeSymbolFromArray);
        } catch (error) {
            console.error('Error:', error);
        }
    }, [selectedCollection, symbolsData, getAccessTokenSilently]);

    useEffect(() => {
        onRemoveGridReady();
    }, [selectedCollection, symbolsData, onRemoveGridReady]);

    const handleSelectionChanged = (event) => {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map((node) => node.data);
        setSelectedRows(selectedData);
    };

    const handleAddToCollection = async () => {
        try {
            const selectedSymbols = selectedRows.map((row) => row.symbol_id);
            const url = `${process.env.REACT_APP_API_HOST}/api/collections/link/multiple`;

            const selectedCollectionId = parseInt(selectedCollection, 10);
            const collectionName = listCollections.find(collection => collection.collection_id === selectedCollectionId)?.collection_name || "Unknown Collection";

            const token = await getAccessTokenSilently({
                audience: "https://uptrenddowntrend.com/api",
                scope: "openid profile email",
            });

            const fetchConfig = {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    symbol_id: selectedSymbols,
                    collection_id: selectedCollectionId,
                }),
            };

            const response = await fetch(url, fetchConfig);
            const responseData = await response.json();
            if (response.status === 200) {
                let addedSymbolsMessage = '';
                let duplicateSymbolsMessage = '';

                if (responseData.added_collections.length > 0) {
                    addedSymbolsMessage = `Added symbols: ${responseData.added_collections.join(', ')}`;
                }

                if (responseData.duplicate_collections.length > 0) {
                    duplicateSymbolsMessage = `Duplicate symbols: ${responseData.duplicate_collections.join(', ')} not added`;
                }

                let finalMessage = '';
                if (addedSymbolsMessage && duplicateSymbolsMessage) {
                    finalMessage = `${addedSymbolsMessage} to '${collectionName}'\n${duplicateSymbolsMessage} to '${collectionName}'.`;
                } else {
                    finalMessage = `${addedSymbolsMessage}${duplicateSymbolsMessage} to '${collectionName}'` || `No symbols were added or updated in the collection '${collectionName}'.`;
                }

                alert(finalMessage);
                gridRef.current.api.deselectAll(); // Deselect all checkboxes
                fetchSymbols();
            } else {
                alert("Failed to add symbols to the collection: " + responseData.message);
            }
        } catch (error) {
            console.error('Error in handleAddToCollection:', error);
            alert("An error occurred: " + error.message);
        }
    };

    const handleRemoveFromCollection = async () => {
        try {
            const selectedSymbols = selectedRows.map((row) => row.symbol_id);
            const url = `${process.env.REACT_APP_API_HOST}/api/collections/link/delete-multiple`;

            const selectedCollectionId = parseInt(selectedCollection, 10);
            const collectionName = listCollections.find(collection => collection.collection_id === selectedCollectionId)?.collection_name || "Unknown Collection";

            const token = await getAccessTokenSilently({
                audience: "https://uptrenddowntrend.com/api",
                scope: "openid profile email",
            });

            const fetchConfig = {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    symbol_id: selectedSymbols,
                    collection_id: selectedCollectionId
                }),
            };

            const response = await fetch(url, fetchConfig);
            const responseData = await response.json();

            if (response.ok) {
                let removedSymbolsMessage = responseData.removed_symbols && responseData.removed_symbols.length > 0
                    ? `Removed symbols: ${responseData.removed_symbols.join(', ')}`
                    : '';
                let notRemovedSymbolsMessage = responseData.not_removed_symbols && responseData.not_removed_symbols.length > 0
                    ? `Symbols not removed: ${responseData.not_removed_symbols.join(', ')}`
                    : '';

                let finalMessage = '';
                if (removedSymbolsMessage || notRemovedSymbolsMessage) {
                    finalMessage = `${removedSymbolsMessage}${removedSymbolsMessage && notRemovedSymbolsMessage ? '\n' : ''}${notRemovedSymbolsMessage} from '${collectionName}'`;
                    alert(finalMessage);
                    gridRef.current.api.deselectAll(); // Deselect all checkboxes
                    fetchSymbols();
                } else {
                    finalMessage = `No symbols were removed or not removed from the collection '${collectionName}'.`;
                    alert(finalMessage);
                }

            } else {
                console.error('Error in handleRemoveFromCollection:', responseData);
                alert("Failed to remove symbols from the collection: " + responseData.message);
            }
        } catch (error) {
            console.error('Error in handleRemoveFromCollection:', error);
            alert("An error occurred: " + error.message);
        }
    };

    useEffect(() => {
        const intSelectedCollection = parseInt(selectedCollection);
        const foundCollection = collectionsData.find(collection => collection.collection_id === intSelectedCollection);

        if (foundCollection) {
            setOneSelection(foundCollection.collection_name);
        } else {
            console.log("Desired collection not found.");
        }
    }, [selectedCollection, collectionsData]);

    return (
        <>
            <div className="controlpanel-main">
                {/* <div>
                    <h1 className="controlpanel-title">COLLECTIONS</h1>
                </div> */}
                <div>
                    <h1 style={{fontSize: "1.5rem", textTransform: 'uppercase'}}>{user.nickname}'s Collections</h1>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="sec-one-contain container" style={{ display: "flex", justifyContent: "center", padding: '2rem',  flexDirection: 'column' }}>
                            <div className="columns is-centered" style={{border: '1px solid white'}}>
                                <div className="column is-one-third">
                                    <div className="w-full max-w-xs">
                                        <div className="shadow-md rounded px-8 pt-6 pb-8">
                                            <h2 style={{ color: 'white', fontSize: '18px', fontWeight: "bold" }}>My Collections:</h2>
                                            <div className="scrollable-list" style={{ maxHeight: '170px', overflowY: 'scroll', border: '1px solid white', scrollbarWidth: 'auto', backgroundColor: 'white', color: 'black' }}>
                                                {listCollections.map((i, index) => {
                                                    if (i.created_by === userIgn) {
                                                        return (
                                                            <ul style={{ borderBottom: '1px solid lightgrey' }} key={index}>
                                                                <li>{i.collection_name}</li>
                                                            </ul>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <form
                                        onSubmit={handleCollectionSubmit}
                                        className="shadow-md rounded px-8 pt-6 pb-8"
                                        style={{border: '1px solid white'}}
                                    >
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                style={{color: 'white', fontSize: '18px'}}
                                            >
                                                Create New Collection:
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="collection"
                                                type="text"
                                                placeholder="Collection Name"
                                                onChange={(e) => setCollection(e.target.value)}
                                                value={collection}
                                            />
                                        </div>

                                        <div className="flex items-center justify-between" style={{justifyContent: 'center'}}>
                                            <button
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                type="submit"
                                                onClick={handleCollectionSubmit}
                                            >
                                                Create
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="shadow-md rounded px-8 pt-6 pb-8" style={{ border: '1px solid white' }} >
                                    <h2 style={{color: 'white', fontSize: '18px', fontWeight: "bold"}}>Remove Collection:</h2>
                                    <select
                                        placeholder="Select to Remove"
                                        onInput={(event) => setRemoveCollection(event.target.value)}
                                        value={removeCollection}
                                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="remove-collection"
                                    >
                                        <option value="">Select Collection</option>
                                        {listCollections.map((item) => {
                                            return (
                                                <option
                                                    value={item.collection_id}
                                                    key={item.collection_id}
                                                >
                                                    {item.collection_name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <button style={{ border: "1px solid black", backgroundColor: "red", marginTop: ".5rem", padding: ".3rem", borderRadius: "3px", fontWeight: 'bold' }} onClick={handleRemoveCollection} className="btn btn-primary">Remove</button>
                                </div>
                            </div>
                        </div>

                        <div style={{ margin: '2rem' }}>
                            <select
                                id="existingCollection"
                                value={selectedCollection}
                                onChange={(e) => handleCollectionChange(e.target.value)}
                                style={{ color: 'black' }}
                            >
                                <option value="">Select here to manage collection:</option>
                                {listCollections.filter(collection => collection.created_by === userIgn).map((collection) => (
                                    <option key={collection.collection_id} value={collection.collection_id}>
                                        {collection.collection_name}
                                    </option>
                                ))}
                            </select>
                            {selectedCollection !== '' ? (
                                <div style={{ border: '1px solid white', padding: '.5rem', borderRadius: '5px', marginTop: '.3rem' }}>
                                    <h3 style={{ textTransform: 'uppercase', paddingBottom: '.2rem' }}>Symbols In Collection:</h3>
                                    <div style={containerStyle}>
                                        <div className="example-wrapper" style={{ alignItems: "center" }}>
                                            <div style={{ marginBottom: '5px' }}>
                                                <input
                                                    type='text'
                                                    onChange={(e) => setQuickFilterText(e.target.value)}
                                                    onInput={onQuickFilterChanged}
                                                    id='quickFilter'
                                                    placeholder='Search'
                                                    style={{ width: "75%", textAlign: "center", color: 'black' }}
                                                />
                                            </div>
                                            <div
                                                style={gridStyle}
                                                className={
                                                    'ag-theme-alpine'
                                                }
                                            >
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={removeRowData}
                                                    columnDefs={removeColumnDefs}
                                                    quickFilterText={quickFilterText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                                <div style={{border: '1px solid white', padding: '.5rem', borderRadius: '5px', marginTop: '1rem' }}>
                                    <h3 style={{ textTransform: 'uppercase', paddingBottom: '.2rem' }}>No collection selected</h3>
                                    <div style={containerStyle}>
                                        <div className="example-wrapper" style={{ alignItems: "center" }}>
                                            <div
                                                style={gridStyle}
                                                className={
                                                    'ag-theme-alpine'
                                                }
                                            >
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={[]}
                                                    columnDefs={removeColumnDefs}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {selectedCollection !== '' ? (
                                <div style={{border: '1px solid white', padding: '.5rem', borderRadius: '5px', marginTop: '1rem' }}>
                                    <h3 style={{ textTransform: 'uppercase', paddingBottom: '.2rem' }}>Select Symbols to Add or Remove:</h3>
                                    <div style={containerStyle}>
                                        <div className="example-wrapper" style={{ alignItems: "center" }}>
                                            <div style={{ marginBottom: '5px' }}>
                                                <input
                                                    type='text'
                                                    onChange={(e) => setManageQuickFilterText(e.target.value)}
                                                    onInput={onQuickFilterChanged}
                                                    id='quickFilter'
                                                    placeholder='Search'
                                                    style={{ width: "75%", textAlign: "center", color: 'black' }}
                                                />
                                            </div>
                                            <div
                                                style={gridStyle}
                                                className={
                                                    'ag-theme-alpine'
                                                }
                                            >
                                                <AgGridReact
                                                    onSelectionChanged={handleSelectionChanged}
                                                    ref={gridRef}
                                                    rowData={rowData}
                                                    columnDefs={columnDefs}
                                                    defaultColDef={defaultColDef}
                                                    suppressRowClickSelection={true}
                                                    rowSelection={'multiple'}
                                                    onGridReady={onGridReady}
                                                    quickFilterText={manageQuickFilterText}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', margin: '.5rem' }}>
                                                <button onClick={handleAddToCollection} style={{ border: '1px solid black', borderRadius: '3px', fontWeight: 'bold', backgroundColor: '#3B82F6', padding: '.3rem', minWidth: "5rem", marginRight: '1rem' }}>
                                                    Add
                                                </button>
                                                <button onClick={handleRemoveFromCollection} style={{ border: '1px solid black', borderRadius: '3px', fontWeight: 'bold', backgroundColor: '#FF0000', padding: '.3rem', minWidth: "5rem", marginLeft: '1rem' }}>
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Collections;
