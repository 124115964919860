import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './component.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';

function PricePredictionPage() {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const gridRef = useRef();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [syms, setSyms] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState('0'); // Default selection is 0%
  const [selectedChartOption, setSelectedChartOption] = useState('option2');
  const [symbolInput, setSymbolInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [customPercentage, setCustomPercentage] = useState('');
  const [numberOfDays, setNumberOfDays] = useState('');
  const [customPrice, setCustomPrice] = useState(''); // State variable for custom price
  const [customVolume, setCustomVolume] = useState(''); // State variable for custom volume
  const params = useParams();
  const symbol = params.symbol || ""; // Extract symbol from URL parameters

  // Updated isFriday function
  function isFriday(dateString) {
    if (!dateString) return false;
    // Check if dateString is in the format '+1', '+2', etc.
    if (dateString.startsWith('+')) return false;
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day));
    return date.getUTCDay() === 5; // getUTCDay() returns the day of the week (0-6) in UTC
  }

  const cellClickedListener = useCallback((event) => {
    console.log('Cell clicked:', event);
  }, []);

  useEffect(() => {
    const fetchSymbols = async () => {
      const symbolsUrl = `${process.env.REACT_APP_API_HOST}/api/symbols`;

      const symbolsResponse = await fetch(symbolsUrl);

      if (symbolsResponse.ok) {
        const symbolsData = await symbolsResponse.json();
        setSyms(symbolsData);
      } else {
        console.error("Failed to fetch symbols.");
      }
    };

    fetchSymbols();
  }, []);

  const popupParent = useMemo(() => document.body, []);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchPredictionData = async (percentageChange) => {
    if (!symbol) return;

    try {
      const token = await getAccessTokenSilently({
        audience: "https://uptrenddowntrend.com/api",
        scope: "openid profile email",
      });

      const sanitizedPercentageChange = percentageChange.startsWith('+')
        ? percentageChange.substring(1)
        : percentageChange;

      const result = await fetch(
        `${process.env.REACT_APP_API_HOST}/up-percentage/${symbol}/${sanitizedPercentageChange}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!result.ok) {
        throw new Error(`Network response was not ok. Status: ${result.status}`);
      }

      const data = await result.json();

      // Determine the index of the first predictive day
      const lastRealDataIndex = data.findIndex(d => d.data_id !== 0);
      const predictiveDays = data.length - lastRealDataIndex - 1;

      const updatedData = data.map((entry, index) => {
        if (entry.data_id === 0) {
          // Replace market_date with +1, +2, ..., +20
          const predictiveDayNumber = index - lastRealDataIndex;
          entry.market_date = `+${predictiveDayNumber}`;
        }
        return entry;
      });

      setRowData(updatedData);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error.message);
    }
  };

  useEffect(() => {
    let percentageChange = selectedOption === 'custom' ? customPercentage : selectedOption;
    if (percentageChange) {
      fetchPredictionData(percentageChange);
    }
    // Removed getAccessTokenSilently from dependencies
  }, [symbol, selectedOption, customPercentage]);

  const handleCustomPercentageChange = (e) => {
    const value = e.target.value;
    if (/^-?\d*\.?\d*$/.test(value)) {
      setCustomPercentage(value);
    }
  };

  // Handlers for custom price and volume inputs
  const handleCustomPriceChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) { // Allow only numbers and decimal points
      setCustomPrice(value);
    }
  };

  const handleCustomVolumeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Allow only integers
      setCustomVolume(value);
    }
  };

  useEffect(() => {
    const dateNow = new Date();
    let startDate;

    if (selectedChartOption === 'option1') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 2);
    } else if (selectedChartOption === 'option2') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 6);
    } else if (selectedChartOption === 'option3') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 12);
    } else if (selectedChartOption === 'option4') {
      if (numberOfDays !== '') {
        const parsedDays = parseInt(numberOfDays, 10);
        if (!isNaN(parsedDays) && parsedDays > 0) {
          startDate = new Date();
          startDate.setDate(startDate.getDate() - parsedDays);
        }
      }
    }

    if (startDate) {
      const filteredData = rowData
        .filter(entry => {
          const dateString = entry.market_date;
          if (dateString.startsWith('+')) return true; // Include predictive dates
          const [year, month, day] = dateString.split('-').map(Number);
          const entryDate = new Date(Date.UTC(year, month - 1, day));
          return entryDate >= startDate;
        })
        .sort((a, b) => {
          const dateA = a.market_date.startsWith('+') ? Infinity : new Date(a.market_date);
          const dateB = b.market_date.startsWith('+') ? Infinity : new Date(b.market_date);
          return dateA - dateB;
        });

      setChartData(filteredData);
    }
  }, [selectedChartOption, numberOfDays, rowData]);

  const createColumnDefs = (width) => {
    return width > 450
      ? [
          {
            headerName: 'Symbol:',
            field: 'symbol',
            filter: true,
            flex: 1,
            maxWidth: 135,
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Company Name:',
            field: 'company_name',
            filter: true,
            flex: 1,
            maxWidth: 250,
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Market Date:',
            field: 'market_date',
            filter: true,
            flex: 1,
            maxWidth: 150,
            valueFormatter: (params) => {
              const dateString = params.value;
              if (dateString.startsWith('+')) return dateString;
              const [year, month, day] = dateString.split('-').map(Number);
              return `${month}/${day}/${year.toString().slice(-2)}`;
            },
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Closing Price:',
            field: 'closing_price',
            flex: 1,
            maxWidth: 150,
            cellRenderer: (params) => {
              const closingPrice = parseFloat(params.data.closing_price).toFixed(2);
              return <div>${closingPrice}</div>;
            },
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Closing Volume:',
            field: 'closing_volume',
            flex: 1,
            maxWidth: 150,
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Daily Trend:',
            field: 'daily_trend',
            filter: true,
            flex: 1,
            maxWidth: 150,
            cellRenderer: (params) => {
              const trendValue = params.data.daily_trend;
              const relativePercentage = params.data.trend_relative_percentage;
              let color = '#000'; // default color
              if (trendValue > 0) {
                color = '#0ca81e'; // Green for positive values
              } else if (trendValue < 0) {
                color = 'red'; // Red for negative values
              }
              return (
                <div style={{ color: color }}>
                  {trendValue} |{' '}
                  <span>
                    {relativePercentage != null
                      ? `${relativePercentage}%`
                      : 'N/A'}
                  </span>
                </div>
              );
            },
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Smoothed Trend:',
            field: 'smoothed_trend',
            filter: true,
            flex: 1,
            maxWidth: 175,
            cellRenderer: (params) => {
              const trendValue = params.data.smoothed_trend;
              const relativePercentage = params.data.smoothed_relative_percentage;
              let color = '#000'; // default color
              if (trendValue > 0) {
                color = '#0ca81e'; // Green for positive values
              } else if (trendValue < 0) {
                color = 'red'; // Red for negative values
              }
              return (
                <div style={{ color: color }}>
                  {trendValue} |{' '}
                  <span>
                    {relativePercentage != null
                      ? `${relativePercentage}%`
                      : 'N/A'}
                  </span>
                </div>
              );
            },
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
        ]
      : [
          {
            headerName: 'Market Date:',
            field: 'market_date',
            filter: true,
            flex: 1,
            maxWidth: 150,
            headerClass: 'small-header',
            valueFormatter: (params) => {
              const dateString = params.value;
              if (dateString.startsWith('+')) return dateString;
              const [year, month, day] = dateString.split('-').map(Number);
              return `${month}/${day}/${year.toString().slice(-2)}`;
            },
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
            cellStyle: {
              fontSize: '10px',
            },
          },
          {
            headerName: 'Closing Price:',
            field: 'closing_price',
            flex: 1,
            maxWidth: 150,
            headerClass: 'small-header',
            cellRenderer: (params) => {
              const closingPrice = parseFloat(params.data.closing_price).toFixed(2);
              return <div>${closingPrice}</div>;
            },
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
            cellStyle: {
              fontSize: '10px',
            },
          },
          {
            headerName: 'Daily Trend:',
            field: 'daily_trend',
            filter: true,
            flex: 1,
            maxWidth: 150,
            headerClass: 'small-header',
            cellRenderer: (params) => {
              const trendValue = params.data.daily_trend;
              const relativePercentage = params.data.trend_relative_percentage;
              let color = '#000'; // default color
              if (trendValue > 0) {
                color = '#0ca81e';
              } else if (trendValue < 0) {
                color = 'red';
              }
              return (
                <div style={{ fontSize: '10px', color: color }}>
                  {trendValue} |{' '}
                  <span>
                    {relativePercentage != null
                      ? `${relativePercentage}%`
                      : 'N/A'}
                  </span>
                </div>
              );
            },
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          {
            headerName: 'Smoothed Trend:',
            field: 'smoothed_trend',
            filter: true,
            flex: 1,
            maxWidth: 150,
            headerClass: 'small-header',
            cellRenderer: (params) => {
              const trendValue = params.data.smoothed_trend;
              const relativePercentage = params.data.smoothed_relative_percentage;
              let color = '#000'; // default color
              if (trendValue > 0) {
                color = '#0ca81e';
              } else if (trendValue < 0) {
                color = 'red';
              }
              return (
                <div style={{ fontSize: '10px', color: color }}>
                  {trendValue} |{' '}
                  <span>
                    {relativePercentage != null
                      ? `${relativePercentage}%`
                      : 'N/A'}
                  </span>
                </div>
              );
            },
            cellClass: (params) =>
              isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
        ];
  };

  const columnDefs = createColumnDefs(width);

  const [PriceChart, setPriceChart] = useState({
    autoSize: true,
    data: [],
    legend: { enabled: true, position: 'bottom' },
    series: [
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'closing_price',
        title: 'Closing Price',
        stroke: '#800080',
        marker: { enabled: true, size: 4, fill: '#800080' },
      },
    ],
    axes: [
      { type: 'category', position: 'bottom' },
      { type: 'number', position: 'left', crossLines: [{ value: 0, strokeWidth: 2, stroke: '#00008B', lineDash: [6, 3] }] },
    ],
  });

  const [TrendChart, setTrendChart] = useState({
    autoSize: true,
    data: [],
    legend: { enabled: true, position: 'bottom' },
    series: [
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'daily_trend',
        title: 'Daily Trend',
        stroke: '#FF5349',
        marker: { enabled: true, size: 4, fill: '#FF5349' },
      },
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'smoothed_trend',
        title: 'Smoothed Trend',
        stroke: '#FFB427',
        marker: { enabled: true, size: 4, fill: '#FFB427' },
      },
    ],
    axes: [
      { type: 'category', position: 'bottom' },
      { type: 'number', position: 'left', crossLines: [{ value: 0, strokeWidth: 2, stroke: '#00008B', lineDash: [6, 3] }] },
    ],
  });

  useEffect(() => {
    setPriceChart((prevOptions) => ({
      ...prevOptions,
      data: chartData,
    }));
  }, [chartData]);

  useEffect(() => {
    setTrendChart((prevOptions) => ({
      ...prevOptions,
      data: chartData,
    }));
  }, [chartData]);

  const defaultColDef = useMemo(() => ({ sortable: true }), []);

  const handleSymbolInputChange = (e) => {
    const input = e.target.value.toUpperCase();
    setSymbolInput(input);
    if (input.length > 0) {
      const filteredSuggestions = syms.filter(s => s.symbol.startsWith(input)).slice(0, 5);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (symbol) => {
    setSymbolInput(symbol);
    setSuggestions([]);
    navigate(`/predictor/${symbol}`);
  };

  const search = symbol.toUpperCase();

  const symbolHolder = syms.map(s => s.symbol);

  // Existing relative percentage calculations
  let trendHigh = 0;
  let trendLow = 0;
  let smoothedHigh = 0;
  let smoothedLow = 0;
  let trendRP = 0;
  let smoothedRP = 0;

  if (rowData.length > 0) {
    // Since rowData is sorted from newest to oldest, we take the first 65 entries
    const recentData = rowData.slice(0, 65); // Take the first 65 entries (most recent data)

    trendHigh = Math.max(...recentData.map((d) => d.daily_trend));
    trendLow = Math.min(...recentData.map((d) => d.daily_trend));
    smoothedHigh = Math.max(...recentData.map((d) => d.smoothed_trend));
    smoothedLow = Math.min(...recentData.map((d) => d.smoothed_trend));

    const mostRecentData = recentData[0]; // Most recent data is at index 0

    if (mostRecentData) {
      const dailyTrendData = mostRecentData.daily_trend;
      if (dailyTrendData > 0) {
        trendRP = ((dailyTrendData / trendHigh) * 100).toFixed(2);
      } else if (dailyTrendData < 0) {
        trendRP = ((dailyTrendData / trendLow) * 100).toFixed(2);
      }

      const dailySmoothedData = mostRecentData.smoothed_trend;
      if (dailySmoothedData > 0) {
        smoothedRP = ((dailySmoothedData / smoothedHigh) * 100).toFixed(2);
      } else if (dailySmoothedData < 0) {
        smoothedRP = ((dailySmoothedData / smoothedLow) * 100).toFixed(2);
      }
    }
  } else {
    return null;
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (symbolHolder.includes(search)) {
    return (
      <>
        <div className="symbol-details-main" style={{ display: 'flex', position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {/* Symbol Details Section */}
          <div style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '45%' }}>
            {syms.map((symbolItem) => {
              if (symbolItem.symbol === search) {
                return (
                  <div key={symbolItem.symbol} className="symbol_items_container">
                    <ul className="symbol_items">
                      <li className="symbol_title">{symbolItem.symbol}</li>
                      <li className="symbol_cp_name">{symbolItem.company_name}</li>
                      <li className="symbol_exchange">{symbolItem.exchange}</li>
                      <li className="symbol_link">
                        {symbolItem.website ? (
                          <a href={symbolItem.website} target="_blank" rel="noopener noreferrer">{symbolItem.website}</a>
                        ) : "No Website Available"}
                      </li>
                    </ul>
                  </div>
                );
              }
              return null;
            })}
            {width > 450 ? (
              <div style={{ display: 'flex', flexDirection: 'space-around', color: 'white', border: '1px solid white',
                minWidth: '15%', padding: '1rem', borderRadius: '5px', justifyContent: 'center', flexDirection: 'column', height: '142px' }}>
                <div style={{ textTransform: 'uppercase', color: '#00b1ff' }}>Uptrend_Downtrend Predictors:</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ marginRight: '.5rem' }}>
                    <h3 style={{ textDecoration: 'underline' }}>Daily Trend</h3>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Current: <span style={{ marginLeft: '2px', color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{rowData[0].daily_trend}</span></h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {trendHigh}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {trendLow}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Relative Percentage: <span style={{ marginLeft: '2px', color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{trendRP}%</span></h6>
                  </div>
                  <div style={{ marginLeft: '.5rem' }}>
                    <h3 style={{ textDecoration: 'underline' }}>Smoothed Trend</h3>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Current: <span style={{ marginLeft: '2px', color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{rowData[0].smoothed_trend}</span></h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {smoothedHigh}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {smoothedLow}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Relative Percentage: <span style={{ marginLeft: '2px', color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{smoothedRP}%</span></h6>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {width < 450 ? (
            <div style={{ display: 'flex', flexDirection: 'space-around', color: 'white', border: '1px solid white',
              minWidth: '30%', padding: '1rem', borderRadius: '5px', marginBottom: '1rem', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ textTransform: 'uppercase', color: '#00b1ff' }}>Uptrend_Downtrend Predictors:</div>
              <div style={{ padding: '.5rem' }}>
                <h3 style={{ textDecoration: 'underline' }}>Daily Trend</h3>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Current:  <span style={{ marginLeft: '2px', color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{rowData[0].daily_trend}</span></h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {trendHigh}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {trendLow}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Relative Percentage: <span style={{ marginLeft: '2px', color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{trendRP}%</span></h6>
              </div>
              <div style={{ padding: '.5rem' }}>
                <h3 style={{ textDecoration: 'underline' }}>Smoothed Trend</h3>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Current: <span style={{ marginLeft: '2px', color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{rowData[0].smoothed_trend}</span></h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {smoothedHigh}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {smoothedLow}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Relative Percentage: <span style={{ marginLeft: '2px', color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{smoothedRP}%</span></h6>
              </div>
            </div>
          ) : null}

          {/* Predictor Control Panel */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'space-around',
              color: 'white',
              border: '1px solid white',
              minWidth: '15%',
              padding: '1rem',
              borderRadius: '5px',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '142px',
              marginBottom: '0.5rem',
            }}
          >
            <div style={{ textTransform: 'uppercase', color: '#00b1ff' }}>
              Predictor Control Panel:
            </div>
            <div style={{ textTransform: 'uppercase', textSize: '8px' }}>
              Price by Percentage:
            </div>

            <div style={{ display: 'flex', marginLeft: '0.5rem' }}>
              <input
                type="radio"
                id="0"
                name="percentageOptions"
                value="0"
                style={{ marginRight: '.2rem' }}
                onChange={() => setSelectedOption('0')}
                checked={selectedOption === '0'}
              />
              <label
                htmlFor="0"
                style={{
                  color: 'white',
                  marginRight: '.5rem',
                  backgroundColor: '#134f42',
                  padding: '0.2rem 0.5rem',
                  borderRadius: '5px',
                }}
              >
                0%
              </label>

              <input
                type="radio"
                id="+1"
                name="percentageOptions"
                value="+1"
                style={{ marginRight: '.2rem' }}
                onChange={() => setSelectedOption('+1')}
                checked={selectedOption === '+1'}
              />
              <label
                htmlFor="+1"
                style={{
                  color: 'white',
                  marginRight: '.5rem',
                  backgroundColor: '#134f42',
                  padding: '0.2rem 0.5rem',
                  borderRadius: '5px',
                }}
              >
                +1%
              </label>

              <input
                type="radio"
                id="-1"
                name="percentageOptions"
                value="-1"
                style={{ marginRight: '.2rem' }}
                onChange={() => setSelectedOption('-1')}
                checked={selectedOption === '-1'}
              />
              <label
                htmlFor="-1"
                style={{
                  color: 'white',
                  marginRight: '.5rem',
                  backgroundColor: '#134f42',
                  padding: '0.2rem 0.5rem',
                  borderRadius: '5px',
                }}
              >
                -1%
              </label>

              <input
                type="radio"
                id="custom"
                name="percentageOptions"
                value="custom"
                style={{ marginRight: '.2rem' }}
                onChange={() => setSelectedOption('custom')}
                checked={selectedOption === 'custom'}
              />
              <input
                type="text"
                id="custom"
                placeholder="Custom %"
                name="percentageOptions"
                value={customPercentage}
                style={{
                  marginRight: '.2rem',
                  color: 'black',
                  borderRadius: '2px',
                }}
                onChange={handleCustomPercentageChange}
                onClick={() => setSelectedOption('custom')}
              />
            </div>

            {/* Individual labels for Custom Price and Custom Volume */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '.5rem' }}>
                <label style={{ textTransform: 'uppercase', textSize: '8px', marginBottom: '.2rem' }}>Custom Price:</label>
                <input
                  type="text"
                  id="customprice"
                  placeholder="Custom $"
                  name="priceOptions"
                  value={customPrice}
                  style={{
                    marginRight: '.2rem',
                    color: 'black',
                    borderRadius: '2px',
                    width: '66%',
                  }}
                  onChange={handleCustomPriceChange}
                  onClick={() => setSelectedOption('custom')}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <label style={{ textTransform: 'uppercase', textSize: '8px', marginBottom: '.2rem' }}>Custom Volume:</label>
                <input
                  type="text"
                  id="customvolume"
                  placeholder="Custom Volume"
                  name="volumeOptions"
                  value={customVolume}
                  style={{
                    marginRight: '.2rem',
                    color: 'black',
                    borderRadius: '2px',
                    width: '66%',
                  }}
                  onChange={handleCustomVolumeChange}
                  onClick={() => setSelectedOption('custom')}
                />
              </div>
            </div>
          </div>

          {/* Jump to Symbol and Buttons Section */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '.5rem',
            }}
          >
            {/* Jump to Symbol Input */}
            <div style={{ position: 'relative', marginRight: '1rem' }}>
              <input
                type="text"
                placeholder="Jump to Symbol"
                value={symbolInput}
                onChange={handleSymbolInputChange}
                style={{
                  color: 'black',
                  padding: '0.5rem',
                  borderRadius: '5px',
                  width: '150px',
                  marginBottom: '0.5rem',
                }}
              />
              {suggestions.length > 0 && (
                <ul
                  style={{
                    listStyleType: 'none',
                    padding: 0,
                    margin: 0,
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    width: '150px',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                >
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.symbol}
                      onClick={() =>
                        handleSuggestionClick(suggestion.symbol)
                      }
                      style={{
                        padding: '0.5rem',
                        cursor: 'pointer',
                      }}
                    >
                      {suggestion.symbol}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Return to Details Button */}
            <button
              style={{
                color: 'white',
                border: '1px solid white',
                padding: '.3rem',
                borderRadius: '5px',
                backgroundColor: '#134f42',
                marginRight: '0.5rem',
              }}
              onClick={() => navigate(`/details/${symbol}`)}
            >
              Return to Details
            </button>

            {/* Export as CSV Button */}
            <button
              style={{
                color: 'white',
                border: '1px solid white',
                padding: '.3rem',
                borderRadius: '5px',
                backgroundColor: '#134f42',
              }}
              onClick={onBtnExport}
            >
              Export as CSV
            </button>
          </div>

          {/* Grid and Charts */}
          {/* ... (Your existing grid and chart code) */}
          {width > 450 ? (
            <div className="ag-theme-alpine" style={{ width: '80%', maxWidth: '1110px', height: 'calc(100vh - 370px)', overflowX: 'auto', marginBottom: '.5rem' }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
                rowMultiSelectWithClick={true}
                cellSelection={true}
                enableCellTextSelection={true}
                ensureDomOrder={true}
                onCellClicked={cellClickedListener}
              />
            </div>
          ) : (
            <div className="ag-theme-alpine" style={{ width: '80%', height: 'calc(100vh - 470px)', overflowX: 'auto', marginBottom: '.5rem', fontSize: '14px' }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
                rowMultiSelectWithClick={true}
                cellSelection={true}
                enableCellTextSelection={true}
                ensureDomOrder={true}
                onCellClicked={cellClickedListener}
              />
            </div>
          )}

          {/* Charts */}
          <div style={{ color: 'white' }}>
            <div className="details-container">
              <div className="ag-theme-alpine details-grid">
                {/* Add any additional grid elements or configurations here */}
              </div>

              <div style={{ display: "flex", justifyContent: "flex-start", fontSize: '15px', marginLeft: '.5rem' }}>
                <div>
                  <input type="radio" id="option1" name="chartOptions" value="option1" style={{ marginRight: '.2rem' }} onChange={() => setSelectedChartOption('option1')} checked={selectedChartOption === 'option1'} />
                  <label htmlFor="option1" style={{ marginRight: '.5rem' }}>Previous Month</label>
                </div>
                <div>
                  <input type="radio" id="option2" name="chartOptions" value="option2" style={{ marginRight: '.2rem' }} onChange={() => setSelectedChartOption('option2')} checked={selectedChartOption === 'option2'} />
                  <label htmlFor="option2" style={{ marginRight: '.5rem' }}>Previous 6 Months</label>
                </div>
                <div>
                  <input type="radio" id="option3" name="chartOptions" value="option3" style={{ marginRight: '.2rem' }} onChange={() => setSelectedChartOption('option3')} checked={selectedChartOption === 'option3'} />
                  <label htmlFor="option3" style={{ marginRight: '.5rem' }}>Previous Year</label>
                </div>
                <div>
                  <input type="radio"
                    id="option4"
                    name="chartOptions"
                    value="option4"
                    style={{ marginRight: '.2rem' }}
                    onChange={() => setSelectedChartOption('option4')} checked={selectedChartOption === 'option4'}
                  />
                  <input
                    type="text"
                    id="option4"
                    placeholder="Enter Days"
                    name="chartOptions"
                    value={numberOfDays}
                    style={{ marginRight: '.2rem', color: "black", borderRadius: '2px' }}
                    onChange={(e) => setNumberOfDays(e.target.value)}
                    onClick={() => setSelectedChartOption('option4')}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="details-chart" style={{ display: "flex", marginBottom: '1rem' }}>
                  <div style={{ margin: '.5rem' }}>
                    <AgChartsReact options={PriceChart} />
                  </div>
                  <div style={{ margin: '.5rem' }}>
                    <AgChartsReact options={TrendChart} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="error-container">
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'Center', paddingTop: '2rem', color: 'white', fontSize: '2rem' }}>
            SYMBOL DOES NOT EXIST
          </div>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'Center', paddingTop: '2rem', color: 'white', flexDirection: 'column', alignItems: 'center' }}>
            <h6>Would you like to add this symbol?</h6>
            <button className="no-symbol-btn" style={{ border: '1px solid white', maxWidth: '9rem', padding: '.7rem', borderRadius: '5px', marginTop: '.5rem' }}>
              <Link to="/dashboard/controlpanel">ADD SYMBOL</Link>
            </button>
          </div>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'Center', paddingTop: '2rem', color: 'white', flexDirection: 'column', alignItems: 'center' }}>
            <h6>Search for a different symbol</h6>
            <button className="no-symbol-btn" style={{ border: '1px solid white', maxWidth: '12rem', padding: '.8rem', borderRadius: '5px', marginTop: '.5rem' }}>
              <Link to="/details">SEARCH SYMBOL</Link>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default PricePredictionPage;
